(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('provider-segment-rate', {
                parent: 'entity',
                url: '/provider-segment-rate?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.providerSegment.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/provider-segment-rate/provider-segment-rates.html',
                        controller: 'ProviderSegmentRateController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'pc.position,asc',
                        squash: true
                    },
                    providerCountryId: 1,
                    gateway: null,
                    mode: 'AUTO',
                    status: 'ENABLED',
                    firstRun: false
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            providerCountryId: $stateParams.providerCountryId,
                            gateway: $stateParams.gateway,
                            mode: $stateParams.mode,
                            status: $stateParams.status,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('providerSegment');
                        $translatePartialLoader.addPart('providerStatus');
                        $translatePartialLoader.addPart('providerSegmentType');
                        $translatePartialLoader.addPart('transactionMode');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
