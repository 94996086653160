(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PurchaseRecordsController', PurchaseRecordsController);

    PurchaseRecordsController.$inject = ['$filter', '$http', '$state', 'Vendor', 'DateUtils', 'AlertService', 'DataUtils', 'PurchaseRecords', 'PurchaseRecordsTotals', 'ParseLinks', 'paginationConstants', 'pagingParams'];

    function PurchaseRecordsController($filter, $http, $state, Vendor, DateUtils, AlertService, DataUtils, PurchaseRecords, PurchaseRecordsTotals, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.vendorsList = pagingParams.vendorsList;
        vm.clear = clear;
        vm.search = search;
        vm.exportExcel = exportExcel;
        vm.searchQuery = {
            productName: pagingParams.productName,
            productId: pagingParams.productId,
            vendors: pagingParams.vendors,
            purchasePrice: pagingParams.purchasePrice,
            quantity: pagingParams.quantity,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
        };

        initDatePicker();

        if (pagingParams.firstRun) {
            getAllInitialVendors();
        } else {
            getAllVendors()
            loadAll();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                quantity: vm.searchQuery.quantity,
                purchasePrice: vm.searchQuery.purchasePrice,
                vendors: vm.searchQuery.vendors,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                vendorsList: vm.vendorsList,
                firstRun: false
            });
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.fromDate = DateUtils.currentDateFirstMonth();
            vm.toDate = DateUtils.currentDateFirstMonth();
            vm.searchQuery = {
                productName: null,
                productId: null,
                vendors: vm.vendorsList,
                purchasePrice: null,
                quantity: null,
                fromDate: moment(vm.fromDate).subtract(1, "months").format('MM/DD/YYYY'),
                toDate: moment(vm.toDate).format('MM/DD/YYYY'),
            };
            initDatePicker();
            $('#field_vendor').multiselect('selectAll', false);
            $('#field_vendor').multiselect('refresh');

            vm.transition();
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#field_vendor').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('statistics.all'),
                nonSelectedText: translate('statistics.noneSelected'),
                allSelectedText: translate('statistics.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });

            vm.data = [];
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.firstRun = false;
            vm.transition();
        }

        function loadAll() {
            if (vm.searchQuery.fromDate == null || vm.searchQuery.toDate == null) {
                return
            }
            PurchaseRecords.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                fromDate: moment(vm.searchQuery.fromDate).format("YYYY-MM-DD"),
                toDate: moment(vm.searchQuery.toDate).format("YYYY-MM-DD"),
                vendors: vm.searchQuery.vendors,
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                purchasePrice: vm.searchQuery.purchasePrice,
                quantity: vm.searchQuery.quantity,
            }, onSuccess, onError);

            PurchaseRecordsTotals.query({
                fromDate: moment(vm.searchQuery.fromDate).format("YYYY-MM-DD"),
                toDate: moment(vm.searchQuery.toDate).format("YYYY-MM-DD"),
                vendors: vm.searchQuery.vendors,
                productName: vm.searchQuery.productName,
                productId: vm.searchQuery.productId,
                purchasePrice: vm.searchQuery.purchasePrice,
                quantity: vm.searchQuery.quantity,
            }, success);
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.records = data;
            vm.page = pagingParams.page;
        }

        function onError(error) {
            console.error(error);
            AlertService.error(error.data.message);
        }

        function success(data) {
            vm.totalQuantity = data.totalQuantity;
            vm.totalAmount = data.totalAmount;
        }

        function translateMultiSelect() {
            all = translate('statistics.all');
            noneSelected = translate('statistics.noneSelected');
            allSelected = translate('statistics.allSelected');
        }

        function getAllVendors() {
            Vendor.getAsList({}, onSuccess, onError);

            function onSuccess(data) {
                vm.vendors = data;
                vm.vendors.push({id: -1, name: "Not Specified"});
                setTimeout(function () {
                    $('#field_vendor').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_vendor').multiselect('rebuild');
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getAllInitialVendors() {
            Vendor.getAsList({}, onSuccess, onError);

            function onSuccess(data) {
                vm.vendors = data;
                vm.vendors.push({id: -1, name: "Not Specified"});
                getVendorIds(vm.vendors);
                setTimeout(function () {
                    $('#field_vendor').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_vendor').multiselect('rebuild');
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getVendorIds(vendors) {
            vm.vendorsList = [];
            vendors.forEach(function (vendor) {
                vm.vendorsList.push(vendor.id.toString());
            });
            vm.searchQuery.vendors = vm.vendorsList;
        }

        function initDatePicker() {
            if (vm.searchQuery.toDate === null) {
                vm.searchQuery.toDate = moment().format('MM/DD/YYYY');
                pagingParams.toDate = vm.searchQuery.toDate;
            }
            if (vm.searchQuery.fromDate === null) {
                vm.searchQuery.fromDate = moment(vm.searchQuery.toDate, 'MM/DD/YYYY').subtract(1, "months").format('MM/DD/YYYY');
                pagingParams.fromDate = vm.searchQuery.fromDate;
            }
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') === 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') === 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
                $(this).datepicker('update');
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function exportExcel() {
            var data = "fromDate=" + moment(vm.searchQuery.fromDate).format("YYYY-MM-DD") + "&toDate=" + moment(vm.searchQuery.toDate).format("YYYY-MM-DD") + "&vendors=" + (vm.searchQuery.vendors.length === 0 ? [] : vm.searchQuery.vendors) + "&productName=" + (vm.searchQuery.productName === null ? 'UNKNOWN' : vm.searchQuery.productName) + (vm.searchQuery.productId === null ? "" : "&productId=" + vm.searchQuery.productId) + "&purchasePrice=" + (vm.searchQuery.purchasePrice === null ? "" : vm.searchQuery.purchasePrice) + (vm.searchQuery.quantity === null ? "" : "&quantity=" + vm.searchQuery.quantity);
            $http({
                url: 'api/market-stats/purchase-records/excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                saveAs(blob, 'purchase-records-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });
        }
    }
})();
