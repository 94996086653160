(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductQuantityDialogController', ProductQuantityDialogController);

    ProductQuantityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'DepoService', 'Vendor', 'productId', 'variantId', 'totalQuantity', 'name', 'variantName', 'sku', 'purchasingAgentId', 'expirationDate', 'minInventoryQuantity', 'partnerMinInventoryQuantity', 'minOrderQuantity', 'partnerMinOrderQuantity', 'purchasePrice', 'retailPrice', 'vendorId'];

    function ProductQuantityDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, DepoService, Vendor, productId, variantId, totalQuantity, name, variantName, sku, purchasingAgentId, expirationDate, minInventoryQuantity, partnerMinInventoryQuantity, minOrderQuantity, partnerMinOrderQuantity, purchasePrice, retailPrice, vendorId) {
        var vm = this;

        vm.name = name;
        vm.variantName = variantName.trim();
        vm.productId = productId;
        vm.productVariantId = variantId;
        vm.totalQuantity = totalQuantity;
        vm.sku = sku;
        vm.purchasingAgentId = purchasingAgentId;
        vm.expirationDate = expirationDate;
        vm.minInventoryQuantity = minInventoryQuantity;
        vm.partnerMinInventoryQuantity = partnerMinInventoryQuantity;
        vm.minOrderQuantity = minOrderQuantity;
        vm.partnerMinOrderQuantity = partnerMinOrderQuantity;
        vm.purchasePrice = purchasePrice;
        vm.retailPrice = retailPrice;
        vm.vendorId = vendorId;
        vm.quantity = null;
        vm.inventoryType = "MANUALLY_ADDED";
        vm.description = null;
        vm.clear = clear;
        vm.save = save;
        vm.hasVariantName = hasVariantName;
        vm.hasSku = hasSku;
        vm.vendors = Vendor.getAsList();

        function hasVariantName() {
            return vm.variantName !== null && vm.variantName !== undefined && vm.variantName !== "";
        }

        function hasSku() {
            return vm.sku !== null && vm.sku !== undefined && vm.sku !== "";
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var inventory = {
                "productId": vm.productId,
                "productVariantId": vm.productVariantId,
                "inventoryType": vm.inventoryType,
                "quantity": vm.quantity,
                "description": vm.description
            };
            var product;
            if (vm.productVariantId === 0) {
                product = {
                    "id": vm.productId,
                    "vendorId": vm.vendorId,
                    "purchasePrice": vm.purchasePrice,
                    "inventoryHistory": inventory,
                    "purchasingAgentId": vm.purchasingAgentId,
                    "expirationDate": vm.expirationDate,
                    "minInventoryQuantity": vm.minInventoryQuantity,
                    "partnerMinInventoryQuantity": vm.partnerMinInventoryQuantity,
                    "minOrderQuantity": vm.minOrderQuantity,
                    "partnerMinOrderQuantity": vm.partnerMinOrderQuantity
                }
                DepoService.updateB2bPrice(product, onSaveSuccess);
            } else {
                product = {
                    "id": vm.productId,
                    "vendorId": vm.vendorId,
                    "purchasePrice": vm.purchasePrice,
                    "variantId": vm.productVariantId,
                    "inventoryHistory": inventory,
                    "purchasingAgentId": vm.purchasingAgentId,
                    "expirationDate": vm.expirationDate,
                    "minInventoryQuantity": vm.minInventoryQuantity,
                    "partnerMinInventoryQuantity": vm.partnerMinInventoryQuantity,
                    "minOrderQuantity": vm.minOrderQuantity,
                    "partnerMinOrderQuantity": vm.partnerMinOrderQuantity
                }
                DepoService.updateB2bPrice(product, onSaveSuccess);
            }

            function onSaveSuccess(result) {
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }
        }

    }
})();
