(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PurchaseRecords', PurchaseRecords)
        .factory('PurchaseRecordsTotals', PurchaseRecordsTotals);


    PurchaseRecords.$inject = ['$resource'];

    function PurchaseRecords($resource) {
        var resourceUrl = 'api/market-stats/purchase-records';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    PurchaseRecordsTotals.$inject = ['$resource'];

    function PurchaseRecordsTotals($resource) {
        var resourceUrl = 'api/market-stats/purchase-records/totals';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'}
        });
    }
})();
