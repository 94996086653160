(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('stock-management', {
                parent: 'entity',
                url: '/stock-management',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_ANALYST', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'global.menu.market.stockManagement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stock-management/stock-management.html',
                        controller: 'StockManagementController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    productName: null,
                    sku: "",
                    productStatuses: ['AVAILABLE', 'OUT_OF_STOCK'],
                    stockStatus: null,
                    averageWeeksCount: '1',
                    forecastWeeksCount: '1',
                    parentCategoryField: null,
                    childCategoryField: null,
                    vendorsList: null,
                    parentCategories: null,
                    childCategories: null,
                    childCategoryList: [],
                    productAssortment: null
                },

                resolve: {
                    pagingParams: ['$stateParams', function ($stateParams) {
                        return {
                            productName: $stateParams.productName,
                            vendorId: $stateParams.vendorId,
                            sku: $stateParams.sku,
                            productStatuses: $stateParams.productStatuses,
                            stockStatus: $stateParams.stockStatus,
                            averageWeeksCount: $stateParams.averageWeeksCount,
                            forecastWeeksCount: $stateParams.forecastWeeksCount,
                            parentCategoryField: $stateParams.parentCategoryField,
                            childCategoryField: $stateParams.childCategoryField,
                            vendorsList : $stateParams.vendorsList,
                            parentCategories: $stateParams.parentCategories,
                            childCategories: $stateParams.childCategories,
                            childCategoryList: $stateParams.childCategoryList,
                            productAssortment: $stateParams.productAssortment
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stockManagement');
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('productStatus');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
