(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProviderSegment', ProviderSegment);

    ProviderSegment.$inject = ['$resource', 'DateUtils'];

    function ProviderSegment($resource, DateUtils) {
        var resourceUrl = 'api/provider-segments/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'getOnlyActive': {method: 'GET', url: 'api/active/provider-segments', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'getAsList': {
                method: 'GET',
                isArray: true,
                url: 'api/provider-segment-list'
            },
            'getAllByProvider': {
                method: 'GET',
                isArray: true,
                url: 'api/providers/:id/provider-segments'
            }
        });
    }
})();
