(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderSegmentRateController', ProviderSegmentRateController);

    ProviderSegmentRateController.$inject = ['$state', 'ProviderSegment', 'ProviderCountry', 'PayFormRate', 'HermesRate', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$localStorage', '$timeout'];

    function ProviderSegmentRateController($state, ProviderSegment, ProviderCountry, PayFormRate, HermesRate, ParseLinks, AlertService, paginationConstants, pagingParams, $localStorage, $timeout) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.save = save;
        vm.loadFromLocalStorage = loadFromLocalStorage;
        vm.getOneByServiceIdForHermes = getOneByServiceIdForHermes;
        vm.getOneByServiceIdForPayform = getOneByServiceIdForPayform;
        vm.providerCountries = ProviderCountry.getAsList();
        vm.searchQuery = {
            providerCountryId: pagingParams.providerCountryId,
            gateway: pagingParams.gateway,
            mode: pagingParams.mode,
            status: pagingParams.status
        };

        vm.loadFromLocalStorage();

        if (!pagingParams.firstRun) {
            loadAll();
            loadAllPartnerExchangeRate();
        }

        function loadAll() {
            ProviderSegment.getOnlyActive({
                providerCountryId: vm.searchQuery.providerCountryId,
                gateway: vm.searchQuery.gateway,
                mode: vm.searchQuery.mode,
                status: vm.searchQuery.status,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'pc.position') {
                    result.push('pc.position');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.providerSegments = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAllPartnerExchangeRate() {
            PayFormRate.query(function onSuccess(data) {
                var existingData = JSON.parse(localStorage.getItem('payformRate')) || [];
                var updatedData = mergePayFormData(existingData, data);
                localStorage.setItem('payformRate', JSON.stringify(updatedData));

                $timeout(function () {
                    vm.payformRates = updatedData;
                });
            });

            HermesRate.query(function onSuccess(data) {
                var existingData = JSON.parse(localStorage.getItem('hermesRate')) || [];
                var updatedData = mergeHermesData(existingData, data);
                localStorage.setItem('hermesRate', JSON.stringify(updatedData));

                $timeout(function () {
                    vm.hermesRates = updatedData;
                });
            });
        }

        function mergeHermesData(existingData, newData) {
            var dataMap = {};
            angular.forEach(existingData, function (item) {
                dataMap[item.Service] = item;
            });
            angular.forEach(newData, function (item) {
                dataMap[item.Service] = angular.extend({}, item, {updatedAt: new Date().toISOString()});
            });
            return Object.values(dataMap);
        }

        function mergePayFormData(existingData, newData) {
            var dataMap = {};
            angular.forEach(existingData, function (item) {
                dataMap[item.countryCurrency] = item;
            });
            angular.forEach(newData, function (item) {
                dataMap[item.countryCurrency] = angular.extend({}, item, {updatedAt: new Date().toISOString()});
            });
            return Object.values(dataMap);
        }

        function loadFromLocalStorage() {
            var payformRateData = localStorage.getItem('payformRate');
            if (payformRateData) {
                vm.payformRates = JSON.parse(payformRateData);
            }
            var hermesRateData = localStorage.getItem('hermesRate');
            if (hermesRateData) {
                vm.hermesRates = JSON.parse(hermesRateData);
            }
        }

        function getOneByServiceIdForHermes(code) {
            var countryMap = {
                '3': 'KZT',
                '6': 'KGS',
                '5': 'TJS',
                '2': 'RUB',
                '1': 'UZS'
            };
            return countryMap[code] ? getOneByServiceCurrency(countryMap[code]) : [];
        }

        function getOneByServiceCurrency(ServiceCurrency) {
            return vm.hermesRates.find(function (item) {
                return item.ServiceCurrency === ServiceCurrency
            });
        }

        function getOneByServiceIdForPayform(code) {
            var countryMap = {
                '3': 'KZT',
                '6': 'KGS',
                '5': 'TJS',
                '2': 'RUB',
                '1': 'UZS'
            };
            return countryMap[code] ? getOneByCountryCurrency(countryMap[code]) : [];
        }

        function getOneByCountryCurrency(countryCurrency) {
            return vm.payformRates.find(function (item) {
                return item.countryCurrency === countryCurrency
            });
        }

        function save(providerSegment) {
            ProviderSegment.update(providerSegment, onSaveSuccess, onSaveError);

            function onSaveSuccess() {
                loadAll();
            }

            function onSaveError() {
                loadAll();
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                providerCountryId: vm.searchQuery.providerCountryId,
                gateway: vm.searchQuery.gateway,
                mode: vm.searchQuery.mode,
                status: vm.searchQuery.status
            });
        }

        function search() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'pc.position';
            vm.reverse = true;
            vm.searchQuery = {
                providerCountryId: 1,
                gateway: null,
                mode: 'AUTO',
                status: 'ENABLED'
            };
            vm.transition();
        }

        vm.showJsonModal = function (jsonData) {
            try {
                vm.selectedJson = jsonData;
            } catch (e) {
                console.error("Invalid JSON string", e);
                vm.selectedJson = {error: "Invalid JSON format"};
            }
            $('#jsonModal').modal('show');
        };
    }
})();
