(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProductEditController', ProductEditController);

    ProductEditController.$inject = ['$translate', '$timeout', '$scope', '$state', '$stateParams', 'DataUtils', 'entity', 'Product', 'Category', 'ImageStorage', 'ProductCloudImage', 'SellerUser', 'Principal', 'Option', 'ProductOption', 'ProductOptionValue', 'ProductVariantGenerate', '$uibModal', 'ProductVariant', 'ProductPrimeImage', 'AlertService', 'ProductOrigin', 'SpecialCategory', 'ProductTag', 'Vendor', 'TieredDiscount', 'DepoService'];

    function ProductEditController($translate, $timeout, $scope, $state, $stateParams, DataUtils, entity, Product, Category, ImageStorage, ProductCloudImage, SellerUser, Principal, Option, ProductOption, ProductOptionValue, ProductVariantGenerate, $uibModal, ProductVariant, ProductPrimeImage, AlertService, ProductOrigin, SpecialCategory, ProductTag, Vendor, TieredDiscount, DepoService) {
        var vm = this;
        var imageOrder = 0;
        vm.product = entity;
        vm.datePickerOpenStatus = {};
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.isSpecialProduct = false;
        vm.selectedCategories = vm.product.categories;
        vm.calculateSalePrice = calculateSalePrice;
        vm.calculateProfitMargin = calculateProfitMargin;
        vm.updateTags = updateTags;
        vm.onTagAdded = onTagAdded;
        vm.onTagRemoved = onTagRemoved;
        vm.tagClass = "bg-green-turquoise-opacity";
        vm.enTags = getTagsByLanguage('en');
        vm.ruTags = getTagsByLanguage('ru');
        vm.uzTags = getTagsByLanguage('uz');
        vm.idTags = getTagsByLanguage('id');
        vm.koTags = getTagsByLanguage('ko');
        vm.bnTags = getTagsByLanguage('bn');
        vm.urTags = getTagsByLanguage('ur');
        vm.neTags = getTagsByLanguage('ne');
        vm.viTags = getTagsByLanguage('vi');
        vm.switchIsCombinedValue = switchIsCombinedValue;
        vm.getCategoryName = getCategoryName;
        vm.getSelectedSimpleCategoriesName = getSelectedSimpleCategoriesName;
        vm.cleanSlugUrl = cleanSlugUrl;
        vm.vendors = Vendor.getAsList();
        vm.isAdmin = isAdmin();
        vm.isManager = isManager();
        vm.checkTieredDiscount = checkTieredDiscount;
        vm.deleteTieredDiscount = deleteTieredDiscount;
        vm.updateTieredDiscounts = updateTieredDiscounts;
        vm.tieredDiscounts = [];
        vm.invalidRange = invalidRange;

        function switchIsCombinedValue() {
            if (vm.product.isCombined && vm.product.shippingFee == null) {
                vm.product.shippingFee = 0;
            }
        }

        loadCategories();
        loadAllTags();
        translateName();
        setExpirationDate();
        getTieredDiscounts();
        getB2BPrice();


        function loadCategories() {
            Category.query({}, function (data) {
                vm.simpleCategories = data;
                vm.selectedSimpleCategories = vm.selectedCategories;
                translateCategoryName();
                defineParentCategories();
            });
            SpecialCategory.query({}, function (data) {
                vm.specialCategories = data;
                vm.selectedSpecialCategories = vm.selectedCategories;
                defineIsProductSpecial();
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.simpleCategories.length; i++) {
                if (currentLang === 'en') {
                    vm.simpleCategories[i]['name'] = vm.simpleCategories[i]['nameEng'];
                } else {
                    vm.simpleCategories[i]['name'] = vm.simpleCategories[i]['nameUzb'];
                }
            }
            for (var i = 0; i < vm.selectedSimpleCategories.length; i++) {
                if (currentLang === 'en') {
                    vm.selectedSimpleCategories[i]['name'] = vm.selectedSimpleCategories[i]['nameEng'];
                } else {
                    vm.selectedSimpleCategories[i]['name'] = vm.selectedSimpleCategories[i]['nameUzb'];
                }
            }
        }

        function translateName() {
            var currentLang = $translate.use();
            if (currentLang === 'en') {
                vm.product.name = vm.product.nameEng;
            } else {
                vm.product.name = vm.product.nameUzb;
            }
        }

        function defineParentCategories() {
            for (var i = 0; i < vm.simpleCategories.length; i++) {
                vm.simpleCategories[i]['isParent'] = false;
                for (var j = i + 1; j < vm.simpleCategories.length; j++) {
                    if (vm.simpleCategories[i]['id'] === vm.simpleCategories[j]['parentId']) {
                        vm.simpleCategories[i]['isParent'] = true;
                        break;
                    }
                }
            }
        }

        function defineIsProductSpecial() {
            angular.forEach(vm.specialCategories, function (category, key) {
                if (category.id === vm.selectedCategories[0].id) {
                    vm.isSpecialProduct = true;
                    return null;
                }
            });
        }

        vm.options = Option.query();
        vm.isVisible = false;
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.saveImage = saveImage;
        vm.onSaveSuccessImageStorage = onSaveSuccessImageStorage;
        vm.onSaveErrorImageStorage = onSaveErrorImageStorage;
        vm.confirmDelete = confirmDelete;
        vm.deleteProductOption = deleteProductOption;
        vm.saveProductOption = saveProductOption;
        vm.shouldShowOptionAddButton = shouldShowOptionAddButton;
        vm.getProductOptionValues = getProductOptionValues;
        vm.deleteProductOptionValue = deleteProductOptionValue;
        vm.saveProductOptionValue = saveProductOptionValue;
        vm.isActiveTab = isActiveTab;
        vm.generateVariant = generateVariant;
        vm.saveProductVariant = saveProductVariant;
        vm.updateProductVariants = updateProductVariants;
        vm.addProductVariant = addProductVariant;
        vm.autoFillOptionValuesWhileEditing = autoFillOptionValuesWhileEditing;
        vm.autoFillOptionValuesWhileAdding = autoFillOptionValuesWhileAdding;
        vm.productVariant = {};
        vm.productOrigins = ProductOrigin.getAsList();
        vm.setDatePickers = setDatePickers;

        vm.productCloudImage = {};
        vm.uploadCloudImage = uploadCloudImage;
        vm.deleteCloudImage = deleteCloudImage;
        vm.makeCloudImagePrimary = makeCloudImagePrimary;
        vm.getTrackable = getTrackable;
        vm.cloudImageUrls = [];
        vm.hasb2bDescriptionEng = vm.product.b2bDescriptionEng != null;
        vm.hasb2bDescriptionUzb = vm.product.b2bDescriptionUzb != null;
        vm.hasb2bDescriptionRus = vm.product.b2bDescriptionRus != null;
        vm.hasb2bDescriptionKor = vm.product.b2bDescriptionKor != null;

        initNestable();

        getProductImageUrls();

        selectFirstElement();

        if (isProductOptionsDefined()) {
            if (vm.product.productOptions.length > 0) {
                var firstProductOption = vm.product.productOptions[0].id;
            }
        }

        function initNestable() {
            var UINestable = function () {
                return {
                    init: function () {
                        $('#nestable_list').nestable({
                            group: 1, maxDepth: 1
                        });
                    }
                };
            }();

            jQuery(document).ready(function () {
                UINestable.init();
            });
        }

        dateFormat();
        editorOptions();
        getProductOptionValues(firstProductOption);
        setDatePickers();

        function setDatePickers() {
            setTimeout(function () {
                $('.date-product').datepicker({autoclose: true});
                $('[data-toggle="tooltip"]').tooltip();
            }, 0);
        }

        function onSuccess(data, headers) {
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.imageStorage = data;
        }

        SellerUser.query({}, onShippingFeeSuccess, onShippingFeeError);

        function onShippingFeeSuccess(data, headers) {
            vm.users = data;
            if (vm.product.sellerId == undefined || vm.product.sellerId == null) {
                if (Principal.hasAnyAuthority(['ROLE_SELLER'])) {
                    if (vm.users.length > 0) {
                        vm.product.sellerId = vm.users[0].id;
                    }
                }
            }
        }

        function onShippingFeeError(error) {
            AlertService.error(error.data.message);
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
            $('#tab7 .note-editing-area').attr('style', 'direction: rtl;');
        });

        function save() {
            vm.isSaving = true;
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == "saleStartDate") {
                    vm.product.saleStartDate = new Date($(this).val());
                } else if ($(this).attr('name') == "saleEndDate") {
                    vm.product.saleEndDate = new Date($(this).val());
                } else if ($(this).attr('name') == "availableDate") {
                    vm.product.availableDate = new Date($(this).val());
                }
            });

            vm.product.descriptionEng = $('#field_descriptionEng').summernote('code');
            vm.product.shippingEng = $('#field_shippingEng').summernote('code');
            vm.product.descriptionRus = $('#field_descriptionRus').summernote('code');
            vm.product.shippingRus = $('#field_shippingRus').summernote('code');
            vm.product.descriptionUzb = $('#field_descriptionUzb').summernote('code');
            vm.product.shippingUzb = $('#field_shippingUzb').summernote('code');
            vm.product.descriptionIdn = $('#field_descriptionIdn').summernote('code');
            vm.product.shippingIdn = $('#field_shippingIdn').summernote('code');
            vm.product.expirationDate = new Date(vm.expirationDate);

            vm.product.b2bDescriptionEng = (vm.product.b2bDescriptionEng && vm.hasb2bDescriptionEng) ? $('#field_b2bDescriptionEng').summernote('code') : null;
            vm.product.b2bDescriptionRus = (vm.product.b2bDescriptionRus && vm.hasb2bDescriptionRus) ? $('#field_b2bDescriptionRus').summernote('code') : null;
            vm.product.b2bDescriptionUzb = (vm.product.b2bDescriptionUzb && vm.hasb2bDescriptionUzb) ? $('#field_b2bDescriptionUzb').summernote('code') : null;
            vm.product.b2bDescriptionKor = (vm.product.b2bDescriptionKor && vm.hasb2bDescriptionKor) ? $('#field_b2bDescriptionKor').summernote('code') : null;


            if (vm.isSpecialProduct) {
                vm.product.categories = vm.selectedSpecialCategories;
            } else {
                vm.product.categories = vm.selectedSimpleCategories;
            }
            vm.product.isSpecialProduct = vm.isSpecialProduct;

            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.product = result;
            $state.go("product.edit", {id: result.id});
            $scope.$emit('bozorakaAdminApp:productUpdate', result);
            vm.isSaving = false;
            loadProduct();
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getCompressedImage(sourceImage) {
            var quality = 80;
            var outputFormat = 'jpg';
            var compressedImage = jic.compress(sourceImage, quality, outputFormat);
            return compressedImage.src.replace("data:image/jpeg;base64,", "");
        }

        function makeImageStorage(id, $file, data, createdDate, sortOrder) {
            var imageStorage = {
                id: id,
                name: $file.name,
                data: data,
                dataContentType: $file.type,
                sortOrder: sortOrder,
                productId: vm.product.id,
                createdDate: createdDate
            };
            return imageStorage;
        }

        function saveImage(imageStorage) {
            vm.isSaving = true;
            if (imageStorage.id != null) {
                ImageStorage.update(imageStorage, onSaveSuccessImageStorage, onSaveErrorImageStorage);
            } else {
                ImageStorage.save(imageStorage, onSaveSuccessImageStorage, onSaveErrorImageStorage);
            }
        }

        function onSaveSuccessImageStorage(result) {
            vm.isSaving = false;
            vm.product = Product.get({id: $stateParams.id});
            getImageOrder();
            getProductImageUrls();
        }

        function onSaveErrorImageStorage() {
            vm.isSaving = false;
        }

        function confirmDelete(id) {
            ImageStorage.delete({id: id},
                function () {
                    loadProduct();

                });
        }

        function getImageOrder() {
            var imageStorages = vm.product.imageStorages;
            if (imageStorages != null) {
                return imageStorages.length;
            } else {
                return 0;
            }
        }

        function dateFormat() {
            if (vm.product.availableDate != null) {
                vm.availableDate = new Date(vm.product.availableDate);
                vm.availableDate = dateConvert(vm.availableDate);
            }
            if (vm.product.saleStartDate != null) {
                vm.saleStartDate = new Date(vm.product.saleStartDate);
                vm.saleStartDate = dateConvert(vm.saleStartDate);
            }
            if (vm.product.saleEndDate != null) {
                vm.saleEndDate = new Date(vm.product.saleEndDate);
                vm.saleEndDate = dateConvert(vm.saleEndDate);
            }
        }

        function dateConvert(date) {
            if (date != undefined) {
                var month = date.getMonth() + 1;
                var dateMonth = date.getDate();

                return setZero(month) + '/' + setZero(dateMonth) + '/' + date.getFullYear();
            } else {
                return '';
            }
        }

        function dateConvertNewFormat(date) {
            if (date != undefined) {
                var month = date.getMonth() + 1;
                var dateMonth = date.getDate();
                return date.getFullYear() + '.' + setZero(month) + '.' + setZero(dateMonth);
            } else {
                return '';
            }
        }

        function setZero(param) {
            if (param < 10) {
                return '0' + param;
            } else {
                return param;
            }
        }

        function editorOptions() {
            $scope.options = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['fontface', ['fontname']],
                    ['picture', ['picture']],
                    ['video', ['video']],
                    ['insert', ['link']],
                    ['insert', ['rtl', 'ltr']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        vm.toggleTabs = function (tab) {
            var description = $('.description');
            var price = $('.price');
            var extras = $('.extras');
            if (tab === 'description') {
                description.addClass('active');
                price.removeClass('active');
                extras.removeClass('active')
            } else if (tab === 'price') {
                description.removeClass('active');
                price.addClass('active');
                extras.removeClass('active')
            }else if (tab === 'extras'){
                description.removeClass('active');
                price.removeClass('active');
                extras.addClass('active')
            }
        };

        function saveProductOption(optionId) {
            if (isHasOption(optionId) && optionId != undefined) {
                var productOption = {
                    productId: vm.product.id,
                    optionId: optionId
                };
                if (productOption.id !== null) {
                    ProductOption.update(productOption, onSaveSuccess, onSaveError);
                } else {
                    ProductOption.save(productOption, onSaveSuccess, onSaveError);
                }
            }

            function onSaveSuccess(result) {
                loadProduct();
                selectFirstElement();
            }
        }

        function deleteProductOption(productOption) {
            var url = 'app/entities/product-option/product-option-delete-dialog.html';
            var controller = 'ProductOptionDeleteController';
            vm.deleteOption = true;
            clearProductOptionValue();
            dialogConfirmDelete(url, controller, productOption);
        }

        function shouldShowOptionAddButton() {
            if (isProductOptionsDefined()) {
                return vm.product.productOptions.length > 2;
            }
        }

        function saveProductOptionValue(productOptionValue) {
            productOptionValue.productOptionId = vm.productOptionId;
            productOptionValue.productId = vm.product.id;
            if (productOptionValue.id !== null) {
                ProductOptionValue.update(productOptionValue, onSaveSuccess, onSaveError);
            } else {
                ProductOptionValue.save(productOptionValue, onSaveSuccess, onSaveError);
            }

            function onSaveSuccess(result) {
                vm.productOptionValue = null;
                loadProduct();
            }
        }

        function deleteProductOptionValue(productOptionValue) {
            var url = 'app/entities/product-option-value/product-option-value-delete-dialog.html';
            var controller = 'ProductOptionValueDeleteController';
            clearProductOptionValue();
            dialogConfirmDelete(url, controller, productOptionValue);
        }

        function loadProduct() {
            Product.get({id: $stateParams.id}, successProduct);
        }

        function successProduct(data) {
            vm.product = data;
            setExpirationDate();
            if (vm.product.productOptions.length > 0) {
                if (vm.deleteOption) {
                    vm.productOptionId = vm.product.productOptions[0].id;
                    vm.deleteOption = false;
                    selectFirstElement();
                }
                getProductOptionValues(vm.productOptionId);
            } else {
                vm.productOptionValues = null;
            }
        }

        function getProductOptionValues(id) {
            vm.productOptionId = id;
            if (vm.product.productOptions != undefined) {
                for (var i = 0; i < vm.product.productOptions.length; i++) {
                    if (vm.product.productOptions[i]['id'] == id) {
                        vm.productOptionValues = vm.product.productOptions[i].productOptionValues;
                    }
                }
            }
        }

        function setExpirationDate() {
            if (vm.product.expirationDate != null) {
                vm.expirationDate = new Date(vm.product.expirationDate);
                vm.expirationDate = dateConvertNewFormat(vm.expirationDate);
            }

            if (vm.product.productVariants != null) {
                for (var index = 0; index < vm.product.productVariants.length; index++) {
                    if (vm.product.productVariants[index].expirationDate != null) {
                        vm.product.productVariants[index].tmpExpirationDate = new Date(vm.product.productVariants[index].expirationDate);
                        vm.product.productVariants[index].tmpExpirationDate = dateConvertNewFormat(vm.product.productVariants[index].tmpExpirationDate);
                    }
                }
            }
        }

        function getTieredDiscounts() {
            if (vm.product.id === null) return;
            TieredDiscount.getByProduct({productId: vm.product.id}, onSuccess);

            function onSuccess(data) {
                vm.tieredDiscounts = data;
                addEmptyTieredDiscount("B2C");
                addEmptyTieredDiscount("B2B");
            }
        }

        function addEmptyTieredDiscount(clientType) {
            var emptyTieredDiscount = {
                id: null,
                clientType: clientType,
                fromQuantity: null,
                toQuantity: null,
                discount: null,
                visibleState: 'ACTIVE',
                productId: vm.product.id,
                last: true
            }
            vm.tieredDiscounts.push(emptyTieredDiscount);
        }

        function checkTieredDiscount(tieredDiscount) {
            invalidRange();
            if (tieredDiscount.id === null && tieredDiscount.last === true && tieredDiscount.fromQuantity !== null && tieredDiscount.toQuantity !== null && tieredDiscount.discount !== null) {
                tieredDiscount.last = false;
                addEmptyTieredDiscount(tieredDiscount.clientType);
            }

        }

        function getB2BPrice() {
            if (vm.product.id === null) return;
            if (vm.product.productVariants.length === 0) {
                vm.accessToB2B = false;
                DepoService.query({productId: vm.product.id}, onSuccess);

                function onSuccess(data) {
                    vm.b2bPrices = data;
                    if (vm.b2bPrices.length > 0) {
                        vm.accessToB2B = true;
                    }
                }
            } else {
                vm.accessToB2B = false;
                vm.product.productVariants.forEach(function (productVariant) {
                    DepoService.query({productId: vm.product.id, variantId: productVariant.id}, onSuccess);

                    function onSuccess(data) {
                        productVariant.b2bPrices = data;
                        if (productVariant.b2bPrices.length > 0) {
                            vm.accessToB2B = true;
                        }
                    }
                });
            }
        }

        function deleteTieredDiscount(tieredDiscount) {
            var index = vm.tieredDiscounts.indexOf(tieredDiscount);
            if (index > -1) {
                vm.tieredDiscounts.splice(index, 1);
            }
            TieredDiscount.delete(tieredDiscount);
        }

        function updateTieredDiscounts() {
            var requestCount = 0;
            var succesCount = 0;
            for (var i = 0; i < vm.tieredDiscounts.length; i++) {
                var tieredDiscount = vm.tieredDiscounts[i];
                if (tieredDiscount.id === null) {
                    if (tieredDiscount.fromQuantity !== null && tieredDiscount.toQuantity !== null && tieredDiscount.discount !== null) {
                        requestCount++;
                        TieredDiscount.save(tieredDiscount, onSuccess);
                    }
                } else {
                    requestCount++;
                    TieredDiscount.update(tieredDiscount, onSuccess);
                }
            }

            function onSuccess() {
                succesCount++;
                if (succesCount === requestCount) {
                    getTieredDiscounts();
                    AlertService.success("The tieredDiscounts data has been saved");
                }
            }
        }

        function invalidRange() {
            var clonedObjArray = vm.tieredDiscounts.filter(function (tieredDiscount) {
                return tieredDiscount.fromQuantity !== null && tieredDiscount.clientType === 'B2C';
            }).sort(function (a, b) {
                return a.fromQuantity - b.fromQuantity;
            });

            for (var i = 1; i < clonedObjArray.length; i++) {
                var tieredDiscount0 = clonedObjArray[i - 1];
                var tieredDiscount1 = clonedObjArray[i];
                if (tieredDiscount1.fromQuantity !== tieredDiscount0.toQuantity) {
                    vm.invalidParams = true;
                    return;
                }
            }
            var clonedObjArrayB2B = vm.tieredDiscounts.filter(function (tieredDiscount) {
                return tieredDiscount.fromQuantity !== null && tieredDiscount.clientType === 'B2B';
            }).sort(function (a, b) {
                return a.fromQuantity - b.fromQuantity;
            });

            for (var k = 1; k < clonedObjArrayB2B.length; k++) {
                var tieredDiscountB2B0 = clonedObjArrayB2B[k - 1];
                var tieredDiscountB2B1 = clonedObjArrayB2B[k];
                if (tieredDiscountB2B1.fromQuantity !== tieredDiscountB2B0.toQuantity) {
                    vm.invalidParams = true;
                    console.log(vm.invalidParams);
                    return;
                }
            }
            vm.invalidParams = false;
        }

        function isHasOption(optionId) {
            for (var i = 0; i < vm.product.productOptions.length; i++) {
                var productOptions = vm.product.productOptions[i];
                if (productOptions.optionId == optionId) {
                    return false;
                }
            }
            return true;
        }

        function isActiveTab(id) {
            if (firstProductOption == id) {
                return 'active';
            }
        }

        function generateVariant(productId) {
            ProductVariantGenerate.generate({productId: productId}, onSuccessGenerate);

            function onSuccessGenerate() {
                loadProduct();
            }
        }

        function updateProductVariants() {
            var productVariants = makeProductVariants();
            for (var j = 0; j < productVariants.length; j++) {
                var productVariant = productVariants[j];
                if (productVariant.purchasePrice > productVariant.price) {
                    AlertService.error("Retail Price cannot be less than Purchase Price");
                    return;
                }
            }
            ProductVariant.updateList(productVariants, onSaveSuccess);

            function onSaveSuccess() {
                loadProduct();
            }
        }

        function makeProductVariants() {
            var item = $("#nestable_list .dd-item").map(function () {
                return $(this).data("id");
            }).get();
            var productVariantList = [];
            for (var i = 0; i < item.length; i++) {
                for (var j = 0; j < vm.product.productVariants.length; j++) {
                    var productVariantItem = vm.product.productVariants[j];
                    if (vm.product.productVariants[j].id === item[i]) {
                        var productVariant = {};
                        productVariant.id = item[i];
                        productVariant.shippingTag = productVariantItem.shippingTag;
                        productVariant.price = productVariantItem.price;
                        productVariant.purchasePrice = productVariantItem.purchasePrice;
                        productVariant.weight = productVariantItem.weight;
                        productVariant.hidden = productVariantItem.hidden;
                        productVariant.expirationDate = productVariantItem.expirationDate;
                        productVariant.position = i + 1;
                        productVariantList.push(productVariant);
                    }
                }
            }
            return productVariantList;
        }

        function saveProductVariant(productVariant) {
            productVariant.expirationDate = new Date(productVariant.tmpExpirationDate);
            if (productVariant.id !== null && productVariant.id != undefined) {
                ProductVariant.update(productVariant, onSaveSuccess);
            } else {
                ProductVariant.save(productVariant, onSaveSuccess);
            }

            function onSaveSuccess() {
                vm.productVariant.hidden = false;
                loadProduct();
            }

        }

        function addProductVariant() {
            vm.productVariant.productId = vm.product.id;
            saveProductVariant(vm.productVariant);
        }

        vm.deleteProductVariant = function (productVariant) {
            var url = 'app/entities/product-variant/product-variant-delete-dialog.html';
            var controller = 'ProductVariantDeleteController';
            dialogConfirmDelete(url, controller, productVariant);
        };

        function dialogConfirmDelete(url, controller, model) {
            $uibModal.open({
                templateUrl: url,
                controller: controller,
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: model,
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productVariant');
                        $translatePartialLoader.addPart('productOptionValue');
                        $translatePartialLoader.addPart('productOption');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function () {
                loadProduct();
            }, function () {
            });
        }

        function isProductOptionsDefined() {
            return vm.product.productOptions != undefined;
        }

        vm.cropper = function (id, createdDate, sortOrder) {
            var canvas = $("#canvas"),
                context = canvas.get(0).getContext("2d");
            vm.isVisible = true;
            $('.fileInput').on('change', function () {
                $('.cropper-container').remove();
                if (this.files && this.files[0]) {
                    var file = this.files[0];
                    if (file.type.match(/^image\//)) {
                        var reader = new FileReader();
                        reader.onload = function (evt) {
                            var img = new Image();
                            img.onload = function () {
                                context.canvas.height = img.height;
                                context.canvas.width = img.width;
                                var size = Math.min(640, Math.min(img.height, img.width));
                                context.drawImage(img, 0, 0);
                                var options = {
                                    aspectRatio: 1,
                                    data: {
                                        width: size,
                                        height: size
                                    },
                                    imageSmoothingEnabled: true,
                                    imageSmoothingQuality: 'high',
                                    dragMode: 'move',
                                    preview: '.img-preview',
                                };
                                var cropper = canvas.cropper('destroy').cropper(options);
                                $('#btnCrop').unbind().click(function () {
                                    var croppedImageData = $("#canvas").cropper('getCroppedCanvas', {
                                        width: size,
                                        height: size
                                    }).toDataURL("image/png");
                                    vm.setCroppedImage(file, id, createdDate, sortOrder, croppedImageData);
                                    disableCropper();
                                });
                                $('#btnRestore').click(function () {
                                    $("#canvas").cropper('reset');
                                    $("#canvas").empty();
                                });
                                $('#aspectRatio3').click(function () {
                                    options.aspectRatio = $(this).attr('value');
                                    options.data.width = size;
                                    options.data.height = size;
                                    options.imageSmoothingEnabled = true;
                                    options.imageSmoothingQuality = 'high';
                                    $("#canvas").cropper('destroy').cropper(options);
                                });
                                $('#aspectRatio5').click(function () {
                                    size = Math.min(1280, Math.min(img.height, img.width))
                                    options.aspectRatio = $(this).attr('value');
                                    options.data.width = size;
                                    options.data.height = size;
                                    options.cropBoxMovable = true;
                                    options.dragMode = 'none';
                                    options.imageSmoothingEnabled = true;
                                    options.imageSmoothingQuality = 'high';
                                    $("#canvas").cropper('destroy').cropper(options);
                                });
                                $('#zoom-out').click(function () {
                                    $("#canvas").cropper('zoom', 0.1);
                                });
                                $('#zoom-in').click(function () {
                                    $("#canvas").cropper('zoom', -0.1);
                                });
                            };
                            img.src = evt.target.result;
                        };
                        reader.readAsDataURL(this.files[0]);
                    }
                    else {
                        $("#canvas").cropper('destroy');
                        vm.isVisible = false;
                    }
                }
                else {
                    $("#canvas").cropper('destroy');
                    vm.isVisible = false;
                }
            });
        };

        vm.setCroppedImage = function (file, id, createdDate, sortOrder, croppedImageData) {
            $scope.$apply(function () {
                if (id == null) {
                    imageOrder = null;
                } else {
                    imageOrder = sortOrder;
                }
                var image = createImageDom(croppedImageData);
                setTimeout(function () {
                    var compressedImage = getCompressedImage(image);
                    var imageStorage = makeImageStorage(id, file, compressedImage, createdDate, sortOrder);
                    saveImage(imageStorage);
                }, 0);
            });
        };

        function createImageDom(base64Data) {
            var sourceImg = document.createElement("IMG");
            var src = "";
            src += base64Data;
            sourceImg.src = src;
            return sourceImg;
        }

        function disableCropper() {
            vm.isVisible = false;
            $('.cropper-container').remove();
            $('.img-preview').empty();
            $('#fileInput').val('');
            $('#canvas').remove();
            createNewCanvas();
        }

        function createNewCanvas() {
            document.getElementById('canvasBox').innerHTML = '<canvas id="canvas" style="visibility: hidden"></canvas>';
        }

        vm.validateWaivedShippingQuantity = function () {
            if (vm.product.waivedShippingQuantity != null && vm.product.waivedShippingQuantity < 2) {
                vm.errorMassage = "The minimum value should not be less than 2";
            } else if (vm.product.waivedShippingQuantity == null || vm.product.waivedShippingQuantity >= 2) {
                vm.errorMassage = null;
            }
        };

        vm.savePrimeImageId = function (imageId) {
            ProductPrimeImage.update({id: vm.product.id, imageId: imageId}, onSuccess);

            function onSuccess(data) {
                vm.product = data;
                borderSelectedImage();
            }
        };

        vm.borderPrimeImage = function (imageId) {
            if (vm.product.primeImageId == imageId) {
                return "border";
            } else {
                return "bordered";
            }
        };

        vm.imageClicked = function (imageId) {
            hideImageSaveButtons();
            showImageSaveButton(imageId);
            borderSelectedImage(this);
        };

        function hideImageSaveButtons() {
            $(".save-button").css('display', 'none');
        }

        function showImageSaveButton(imageId) {
            $("#save-button-" + imageId).css('display', 'block');
        }

        function borderSelectedImage(selected) {
            $(selected).css('border', '3px solid #27ffeb');
        }

        function selectFirstElement() {
            vm.productVariant.productOptionValue1Id = getFirstOptionValue(0);
            vm.productVariant.productOptionValue2Id = getFirstOptionValue(1);
            vm.productVariant.productOptionValue3Id = getFirstOptionValue(2);
        }

        vm.hasProductOptionValues = function (index) {
            var productOptions = vm.product.productOptions;
            return !angular.isUndefined(productOptions[index])
                && !angular.isUndefined(productOptions[index].productOptionValues)
                && productOptions[index].productOptionValues.length > 0;

        };


        function getFirstOptionValue(index) {
            var productOptions = vm.product.productOptions;
            if (!angular.isUndefined(productOptions)
                && !angular.isUndefined(productOptions[index])
                && !angular.isUndefined(productOptions[index].productOptionValues[0])) {
                return productOptions[index].productOptionValues[0]['id'];
            }
        }

        function clearProductOptionValue() {
            vm.productVariant.productOptionValue1Id = null;
            vm.productVariant.productOptionValue2Id = null;
            vm.productVariant.productOptionValue3Id = null;
        }

        function autoFillOptionValuesWhileEditing(productOptionValue) {
            productOptionValue.nameUzb = productOptionValue.nameEng;
            productOptionValue.nameRus = productOptionValue.nameEng;
            productOptionValue.nameIdn = productOptionValue.nameEng;
            productOptionValue.nameKor = productOptionValue.nameEng;
            productOptionValue.nameBen = productOptionValue.nameEng;
            productOptionValue.nameUrd = productOptionValue.nameEng;
            productOptionValue.nameNep = productOptionValue.nameEng;
            productOptionValue.nameVie = productOptionValue.nameEng;
            return productOptionValue;
        }

        function autoFillOptionValuesWhileAdding(engOptionValue) {
            vm.productOptionValue.nameUzb = engOptionValue;
            vm.productOptionValue.nameRus = engOptionValue;
            vm.productOptionValue.nameIdn = engOptionValue;
            vm.productOptionValue.nameKor = engOptionValue;
            vm.productOptionValue.nameBen = engOptionValue;
            vm.productOptionValue.nameUrd = engOptionValue;
            vm.productOptionValue.nameNep = engOptionValue;
            vm.productOptionValue.nameVie = engOptionValue;
        }

        function getTrackable() {
            var trackable = false;
            if (!vm.product.productVariants.length) {
                trackable = vm.product.trackable && vm.product.quantity > 0;
            } else {
                for (var index = 0; index < vm.product.productVariants.length; index++) {
                    trackable = trackable || (vm.product.productVariants[index].trackable && vm.product.productVariants[index].quantity > 0);
                }
            }
            return trackable;
        }

        vm.setData = function ($file, productCloudImage) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        productCloudImage.data = base64Data;
                        productCloudImage.dataContentType = $file.type;
                        productCloudImage.name = $file.name;
                        productCloudImage.productId = vm.product.id;
                    });
                });
            }
        };

        function uploadCloudImage () {
            vm.isSaving = true;
            ProductCloudImage.save(vm.productCloudImage, onCloudUploadSuccess, onCloudUploadError);
        }
        function onCloudUploadSuccess (result) {
            $scope.$emit('bozorakaAdminApp:productCloudImageCreate', result);
            vm.isSaving = false;
            vm.productCloudImage = {};
            getProductImageUrls();
        }

        function onCloudUploadError () {
            vm.isSaving = false;
        }

        function getProductImageUrls () {
            if ($stateParams.id !== undefined) {
                ProductCloudImage.product({id: $stateParams.id, size: 230}, onUrlSuccess);
            }
        }

        function onUrlSuccess(data) {
            vm.cloudImageUrls = data;
        }

        function deleteCloudImage(cloudImageId) {
            $uibModal.open({
                templateUrl: 'app/entities/product-cloud-image/product-cloud-image-delete-dialog.html',
                controller: 'ProductCloudImageDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['ProductCloudImage', function(ProductCloudImage) {
                        return ProductCloudImage.get({id : cloudImageId}).$promise;
                    }]
                }
            }).result.then(function () {
                getProductImageUrls();
            });
        }

        function makeCloudImagePrimary(cloudImageId) {
            ProductCloudImage.primary({id: cloudImageId}, onPrimarySuccess);
        }

        function onPrimarySuccess(data) {
            if (data.status) {
                $scope.$emit('Primary image changed');
                getProductImageUrls();
            } else {
                $scope.$emit('Error on changing primary image');
            }
        }

        function calculateSalePrice() {
            if (vm.product.salePercent < 0) {
                vm.product.salePercent = 0;
            }
            vm.product.salePrice = Math.round(vm.product.retailPrice * (100 - vm.product.salePercent) / 100);
        }

        function getTagsByLanguage(language) {
            if (vm.product.productTags) {
                return vm.product.productTags.filter(function (tag) {
                    return tag.language === language;
                });
            }
        }

        function loadAllTags() {
            ProductTag.getAsList({}, function (data) {
                vm.tagList = data;
            });
        }

        function updateTags($query, language) {
            return vm.tagList.filter(function(tag) {
                return tag.name.toLowerCase().indexOf($query.toLowerCase()) != -1 && tag.language === language;
            });
        }

        function onTagAdded($tag, language) {
            $tag.language = language;
            $tag.productId = vm.product.id;
            ProductTag.add($tag);

        }

        function onTagRemoved($tag, language) {
            $tag.language = language;
            $tag.productId = vm.product.id;
            ProductTag.remove($tag);
        }

        function calculateProfitMargin() {
            if (vm.product.hasDiscount) {
                if (vm.product.vatIncluded) {
                    return (((vm.product.salePrice - vm.product.purchasePrice) / vm.product.salePrice) / 1.1) * 100;
                }
                return ((vm.product.salePrice - vm.product.purchasePrice) / vm.product.salePrice) * 100;
            } else {
                if (vm.product.vatIncluded) {
                    return (((vm.product.retailPrice - vm.product.purchasePrice) / vm.product.retailPrice) / 1.1) * 100;
                }
                return ((vm.product.retailPrice - vm.product.purchasePrice) / vm.product.retailPrice) * 100;
            }
        }

        function getCategoryName(category) {
            if (!category.parentId) {
                return "--";
            }
            for (var i = 0; i < vm.simpleCategories.length; i++) {
                if (vm.simpleCategories[i]['id'] === category.parentId) {
                    return vm.simpleCategories[i]['name'];
                }
            }
            return "--";
        }

        function getSelectedSimpleCategoriesName() {
            var selectedSimpleCategoriesName = "";
            var index = 0;
            for (var i = 0; vm.selectedSimpleCategories && i < vm.selectedSimpleCategories.length; i++) {
                var isParent = false;
                for (var j = 0; vm.selectedSimpleCategories && j < vm.selectedSimpleCategories.length; j++) {
                    if (vm.selectedSimpleCategories[i].id === vm.selectedSimpleCategories[j].parentId) {
                        isParent = true;
                        break;
                    }
                }
                if (isParent) {
                    continue;
                }
                if (index !== 0) {
                    selectedSimpleCategoriesName = selectedSimpleCategoriesName + ", " + vm.selectedSimpleCategories[i].name;
                } else {
                    selectedSimpleCategoriesName = selectedSimpleCategoriesName + vm.selectedSimpleCategories[i].name;
                    index++;
                }
            }
            return selectedSimpleCategoriesName;
        }
        function cleanSlugUrl() {
            vm.product.slugUz = vm.product.slugUz.replace(/([^a-z0-9-]+)/gi, '');
            vm.product.slugRu = vm.product.slugRu.replace(/([^a-z0-9-]+)/gi, '');
            vm.product.slugEn = vm.product.slugEn.replace(/([^a-z0-9-]+)/gi, '');
            vm.product.slugKo = vm.product.slugKo.replace(/([^a-z0-9-]+)/gi, '');
        }

        function isAdmin(){
            return Principal.hasAnyAuthority(['ROLE_ADMIN']);
        }

        function isManager(){
            return Principal.hasAnyAuthority(['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER', 'ROLE_WAREHOUSE_MANAGER']);
        }

    }
})();
